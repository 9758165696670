<template>
<Navbar :user="user" />
  <section class="instructor-details-area pt-100 pb-70" v-if="!isLoading">
    <div class="container">
      <div class="instructor-details-desc shadow-lg">
        <div class="row">
          <div class="col-lg-2 col-md-2">
            <div class="instructor-details-sidebar" v-if="user.avatar">
              <img v-bind:src="'https://apitraining.vipawaworks.com/' + user.avatar" class="rounded-circle" alt="">
            </div>
            <div class="instructor-details-sidebar" v-else>
              <img src="@/assets/img/team/1.jpg" alt="image" class="rounded-circle" />
            </div>
            <div class="instructor-details">
              <h6 class="text-center">{{ user.fname }} {{ user.lname }}</h6>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <div class="section-title text-left">
                <h4 class="text-center">Courses by {{ user.fname }} {{ user.lname }}</h4>
            </div>
            <div class="row">
                <div class="col-md-3" v-for="course in courses" :key="course.id">
                  <SingleCourse :course="course" />
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div v-else>
    <Loading />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import axios from 'axios'
import Loading from '@/components/Loading.vue'
import SingleCourse from '@/components/SingleCourse.vue'
export default {
 components: { Navbar,Loading,SingleCourse },
   data(){
      return {
          drawer: true,
          currentUser: {},
          user: {},
          jobs: {},
          token: localStorage.getItem('user_data'),
          subscriber: {},
          courses: {},
          isLoading: true,
      }
  },
  methods: {
    getCourses(){
      let id = this.$route.params.id
      axios.get('https://apitraining.vipawaworks.com/api/course/get_course_by_user/' + id ).then(response => {
          this.courses = response.data
      }).catch(errors => {
          this.errors = errors.response.data.errors
      }).finally(() => {
          setTimeout(function () {
              this.isLoading =  false
          }.bind(this), 500);
      })
    },
    getUser(){
      axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
          this.user = response.data
      }).catch(errors => {
         this.authError = errors.response.data.errors
      })
    },
  },
  created(){
      document.title = `Dashboard - Training`
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
      this.getCourses()
      this.getUser()
  }

}
</script>

<style>
</style>